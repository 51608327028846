// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-companies-js": () => import("./../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-get-pack-js": () => import("./../../src/pages/get-pack.js" /* webpackChunkName: "component---src-pages-get-pack-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merci-pack-js": () => import("./../../src/pages/merci-pack.js" /* webpackChunkName: "component---src-pages-merci-pack-js" */),
  "component---src-pages-pricing-js": () => import("./../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-signup-js": () => import("./../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-stripe-js": () => import("./../../src/pages/stripe.js" /* webpackChunkName: "component---src-pages-stripe-js" */),
  "component---src-pages-tos-js": () => import("./../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-welcome-js": () => import("./../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

