/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import Amplify, { Auth } from 'aws-amplify';
// import awsConfig from "./src/aws-exports";
import awsConfig from './src/aws-exports-cognitoMainApp';
import { setUser } from './src/utils/auth';

Amplify.configure(awsConfig);

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        userAttributes: user.attributes,
        username: user.username,
        userPoolId: user.pool.userPoolId,
        clientId: user.pool.clientId,
      };
      setUser(userInfo);
    })
    .catch(err => {
      window.localStorage.setItem('gatsbyUser', null);
    });
    
  
  console.log("ReactDOM.render has executed");

    window._intro_bar = window._intro_bar ? window._intro_bar : {};
    let _intro_bar = window._intro_bar ? window._intro_bar : {};
    
    console.log('window', window);
    console.log('window._intro_bar', window._intro_bar);
    _intro_bar.account_id = '5f38e8383099d';  
    console.log('_intro_bar', _intro_bar);
    
    var i, e;
    i = document.createElement("script");
    i.type = 'text/javascript';
    i.async = 1;
    i.src = "//cdn.introbar.com/js/v1.js";
    e = document.getElementsByTagName("script")[0];
    e.parentNode.insertBefore(i, e);
    
    console.log('window._intro_bar', window._intro_bar);
};
