/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const cognitoMainApp = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id:
    'eu-central-1:f37f887c-1dbc-4a9a-92fa-0d50af541941',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_TQj3cssPd',
  aws_user_pools_web_client_id: 'j3e4a6kn1paj1l9pngg80u3sk',
  oauth: {
    domain: 'remotementors-preprod-preprod.auth.eu-central-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: process.env.GATSBY_COGNITO_REDIRECTSIGNIN,
    redirectSignOut: process.env.GATSBY_COGNITO_REDIRECTSIGNOUT,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'payment',
      endpoint:
        'https://f6yhdf79qi.execute-api.eu-central-1.amazonaws.com/preprod',
      region: 'eu-central-1',
    },
  ],
};

export default cognitoMainApp;
