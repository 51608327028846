import { Auth } from 'aws-amplify';

const isBrowser = typeof window !== `undefined`;

export const setUser = userInfo => {
  window.localStorage.gatsbyUser = JSON.stringify(userInfo);
  window.localStorage.stripeUser = JSON.stringify(userInfo);
};

export const setPassword = password => {
  window.localStorage.password = JSON.stringify(password);
};

export const getPassword = () => {
  if (window.localStorage.password) {
    let password = JSON.parse(window.localStorage.password);
    return password ? password : {};
  }
  return {};
};

export const getUser = () => {
  if (window.localStorage.gatsbyUser) {
    let user = JSON.parse(window.localStorage.gatsbyUser);
    return user ? user : {};
  }
  return {};
};

export const getStripeUser = () => {
  if (window.localStorage.stripeUser) {
    let user = JSON.parse(window.localStorage.stripeUser);
    return user ? user : {};
  }
  return {};
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();
  if (user) return !!user.username;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = callback => {
  if (!isBrowser) return;
  setUser({});
  callback();
};

export const checkUserCognito = async () =>
  await Auth.currentAuthenticatedUser()
    .then(user => {
      console.log('user ', user);
    })
    .catch(error => {
      console.log('error ', error);
    });

// const signUpEmail = async email => {
//   const password = Math.random()
//     .toString(36)
//     .slice(-8);
//   // see https://stackoverflow.com/a/9719815

//   setPassword(password);

//   try {
//     const data = await Auth.signUp({
//       username: email,
//       password: password,
//       attributes: {
//         email, // optional
//       },
//     });

//     setState({ stage: 1 });

//     const userInfo = {
//       userAttributes: {
//         name: email,
//         email: email,
//       },
//       username: email,
//     };
//     setUser(userInfo);

//     signIn(email, password);

//     console.log('userInfo', userInfo);
//   } catch (err) {
//     setState({ error: err });
//     console.log('error signing up...', err);

//     // its possible that someone started buying and stopped
//     // then their account already exists so Auth.signUp will fail
//     // We do not need to create a new account, but we need their email for Stripe
//     // Best would be to sign them in
//     if (err.code === 'UsernameExistsException') {
//       const userInfo = {
//         userAttributes: {
//           name: email,
//           email: email,
//         },
//         username: email,
//       };
//       setUser(userInfo);

//       // window.location.href = '/stripe';
//     }
//   }

// };

// const signIn = async (email, password) => {

//   console.log(email, password);

//   try {
//     await Auth.signIn("contactokaou@gmail.com", "op5cilrw")
//       .then(user => console.log(user))
//       .catch(err => console.log(err));

//     setState({ stage: 1 });
//     const user = await Auth.currentAuthenticatedUser();

//     const userInfo = {
//       userAttributes: user.attributes,
//       username: user.username,
//     };
//     setUser(userInfo);
//   } catch (err) {
//     setState({ error: err });
//     console.log('error signing up...', err);
//   }

// }
